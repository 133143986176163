// public/assets/sass/content/base/grid/_essentials.scss
//values in em
export const extraSmallDevice = "35";
export const smallDevice = "48";
export const mediumDevice = "64";
export const largeDevice = "80";
export const slightlyLargeDevice = "90";
export const extraLargeDevice = "100";
export const colors = {
    insiderBlue: "#3695d8",
    black: "#000000",
    brownGrey: "#a8a8a8",
    darkPink: "#b22550",
    greyBackground: "#fafafa",
    greyBorder: "#dddfe2",
    greyIconHeading: "#373435",
    greyLoading: "#f2efef",
    greySecondary: "#999999",
    greyText: "#676465",
    insiderPink: "#ed1d5f",
    insiderRed: "#c20000",
    primaryBlue: "#31c0f0",
    primaryDark: "#0c172f",
    primaryPink: "#ec1066",
    secondaryGreen: "#00534c",
    secondaryPurple: "#7a2a8f",
    secondaryYellow: "#fbed21",
    successGreen: "#45cf12",
    tertiaryGreen: "#00a89c",
    tertiaryOrange: "#f05b2b",
    tertiaryPink: "#f6b8d3",
    warningYellow: "#ffc81e",
    transparent: "rgba(0, 0, 0, 0)",
    transparentBlack: "rgba(0, 0, 0, 0.6)",
    darkGray: "#333",
    "dark-gray": "#666",
    gray: "#999",
    "slight-light-gray": "#abadb3",
    "light-gray": "#ccc",
    "slightly-lighter-gray": "#d0d0d0",
    "lighter-gray": "#eee",
    "lightest-gray": "#f7f7f7",
    "royal-gray": "#373435",
    white: "#fff",
    bodyText: "#373435",
    pink: "#ed1d5f",
    actionBlue: "#29bcff",
    whatsAppGreen: "#25D366",
    lightRed: "#ff8f8f",
    zoomBlue: "#003bd5",
    subTextGrey: "#7a7c82",
    faintGrey: "#f0f1f2",
    dimGrey: "rgb(255,255,255, 0.4)",
    descriptionColor: "#202226",
    borderColor: "#e1e3e6",
    disabledButton: "#e2e3e6",
    paytmBlue: "#00b9f5",
    homeGoOut: "#10c4ff",
    passOrange: "#f05b2b",
    passPurple: "#7a2a8f",
    grey400: "#e1e3e6",
    transparentLightBlack: "rgba(13, 15, 19, 0.5)",
    transparenWhite: "rgba(255, 255, 255, 0.3)",
    lightBlue: "rgba(49, 192, 240, 0.1)",
    slightlyDarkBlue: "#3ec4f0",
    lightPink: "#fee7f0",
    cancelRed: "#DA1E28",
    orange: "#ffbf00",
    lightGrey: "#46474d",
    faintGreen: "#EEFAF0",
    dimBlue: "#eaf9fd",
    boxShadow: "rgba(0, 0, 0, 0.08)",
    paytmProcessing: "rgb(255, 165, 0)",
    paytmSuccess: "rgb(9, 172, 99)",
    paytmError: "rgb(253, 92, 92)",
    paytmRefunded: "rgb(9, 172, 99)",
    paytmCancelled: "rgb(9, 172, 99)",
    paytmBackground: "#F5F9FE",
    lightGray: "#45474D",
    headingBlack: "#0D0F13",
    selectGreen: "#51cc69",
    homeStayIn: "#00BCBF",
    green: "#6FAD0E",
    facebookBlue: "#3b5998",
    googleRed: "#dd4b39",
    hoverActive: "#2889cd",
    toggleSwitchGray: "#4e4e4e",
    toggleSwitchLayer: "#ebf7fc",
    toggleSwitchLayerActive: "#fcebeb",
    darkNavyText: "#0d162f",
    cityOverlayBG: "rgba(12, 23, 47, 0.8)",
    iconBG: "#F5F6F7",
    backgroundPink: "#FEF3F7",
    backgroundBlue: "#F5FCFE",
    errorLightRed: "#FBE8E9",
    postPaidBlue: "#E0F5FD",
    postPaidLink: "#00B8F5",
    "brand-secondary-blue-light": "#102842",
    "brand-secondary-pink-light": "#221634",
    "hover-primary-blue": "#242E44",
    "tertiary-orange-light": "#3A252E",
    "border-grey": "rgba(255, 255, 255, 0.2)",
    "border-grey-1": "rgba(255, 255, 255, 0.1)",
    blueBg: "#1A4F9C",
    darkBlueBg: "#234384",
    blackShadow: "rgba(0, 0, 0, 0.2)",
    golden: "#E2BB60",
    lightGolden: "rgba(226, 187, 96, 0.30)",
    lightGreen: "#52CC6A",
    lightBlue: "#E8EDF5",
    lightRed: "#ED2F21",
    insiderBGLight: "#F9F9FA",
    greyDark: "#121D34",
    insiderGrey: "#E7E8EA",
    iconBlue: "#31C0F0",
    insiderLightBG: "#FCFCFD",
    lightYellow: "#FFF9E5",
    slightDarkBlack: "rgba(0,0,0,0.95)",
    darkGreen: "#052924",
    parrotGreen: "#17CEB2",
    "border-grey-2": "rgba(255, 255, 255, 0.05)",
    lightPink1: "#EFE3FF",
    offWhite: "rgba(255,255,255, 0.95)",
    lighGreen: "#D1F5F0",
    lightSubgrey: "#9EA2AC",
    bgLightSubGrey: "#F3F3F5",
    inputDisable: "#FBFBFB",
    secBtnDark: "#3D4559",
    dividerGrey: "#121D340F",
    greyCopy: "#555D6D",
    greyTextInactive: "#CED1D5",
    grayStroke: 'rgba(12, 23, 47, 0.10)',
    darkpurple: '#BF41FF',
    primaryYellow: '#FFE000',
    dividerColor: 'rgba(255, 255, 255, 0.25)'
};
const DEFAULT_INTER_FONT_SETTINGS = {
    "font-family": "Inter, sans-serif",
    "font-stretch": "normal",
    "font-style": "normal",
    "letter-spacing": "normal"
};
const INTER_BOLD = Object.assign({}, DEFAULT_INTER_FONT_SETTINGS, {
    "font-weight": "bold"
});
const INTER_REGULAR = Object.assign({}, DEFAULT_INTER_FONT_SETTINGS, {
    "font-weight": "normal"
});
const KOROLEV_CONDENSED = {
    "font-family": "korolev-condensed, sans-serif",
    "font-stretch": "condensed",
    "font-style": "normal",
    "font-weight": "bold",
    color: colors.primaryDark
};
export const typography = {
    chromeBody: Object.assign({}, INTER_REGULAR, {
        "font-size": "16px",
        "line-height": "1.31",
        color: colors.greyText
    }),
    chromeHeader1: Object.assign({}, INTER_BOLD, {
        "font-size": "38px",
        "line-height": "1.11",
        "letter-spacing": "-0.4px",
        color: colors.primaryDark
    }),
    chromeHeader2: Object.assign({}, INTER_BOLD, {
        "font-size": "28px",
        "line-height": "1.11",
        "letter-spacing": "-0.3px",
        color: colors.primaryDark
    }),
    chromeHeader3: Object.assign({}, INTER_BOLD, {
        "font-size": "21px",
        "line-height": "1.14",
        "letter-spacing": "-0.2px",
        color: colors.primaryDark
    }),
    chromeHeader4: Object.assign({}, INTER_BOLD, {
        "font-size": "16px",
        "line-height": "1.19",
        color: colors.primaryDark
    }),
    chromeHeader5: Object.assign({}, INTER_REGULAR, {
        "font-size": "12px",
        "line-height": "1",
        "letter-spacing": "0.2px",
        color: colors.greyText
    }),
    chromeHeader6: Object.assign({}, INTER_REGULAR, {
        "font-size": "9px",
        "line-height": "1",
        "letter-spacing": "0.4px",
        color: colors.greyText
    }),
    chromeSmall: Object.assign({}, INTER_REGULAR, {
        "font-size": "12px",
        "line-height": "1.25",
        color: colors.greyText
    }),
    chromeTiny: Object.assign({}, INTER_REGULAR, {
        "font-size": "9px",
        "line-height": "1.33",
        "letter-spacing": "0.2px",
        color: colors.greyText
    }),
    contentBody: Object.assign({}, INTER_REGULAR, {
        "font-size": "14px",
        "line-height": "1.5",
        "letter-spacing": "-0.3px",
        color: colors.greyText
    }),
    contentHeader1: Object.assign({}, INTER_BOLD, {
        "font-size": "33px",
        "line-height": "1.03",
        "letter-spacing": "-0.3px",
        color: colors.primaryDark
    }),
    contentHeader2: Object.assign({}, INTER_BOLD, {
        "font-size": "25px",
        "line-height": "1.12",
        "letter-spacing": "-0.2px",
        color: colors.primaryDark
    }),
    contentHeader3: Object.assign({}, INTER_BOLD, {
        "font-size": "19px",
        "line-height": "1.16",
        "letter-spacing": "-0.2px",
        color: colors.primaryDark
    }),
    contentHeader4: Object.assign({}, INTER_BOLD, {
        "font-size": "14px",
        "line-height": "1.14",
        "letter-spacing": "-0.15px",
        color: colors.primaryDark
    }),
    contentHeader5: Object.assign({}, INTER_REGULAR, {
        "font-size": "11px",
        "line-height": "1.27",
        "letter-spacing": "-0.12px",
        color: colors.greyText
    }),
    contentHeader6: Object.assign({}, INTER_REGULAR, {
        "font-size": "8px",
        "line-height": "1.5",
        "letter-spacing": "-0.08px",
        color: colors.greyText
    }),
    contentHeaderGiant: Object.assign({}, INTER_BOLD, {
        "font-size": "44px",
        "line-height": "1.09",
        "letter-spacing": "-0.7px",
        color: colors.primaryDark
    }),
    contentSmall: Object.assign({}, INTER_REGULAR, {
        "font-size": "11px",
        "line-height": "1.27",
        "letter-spacing": "-0.2px",
        color: colors.greyText
    }),
    contentTiny: Object.assign({}, INTER_REGULAR, {
        "font-size": "8px",
        "line-height": "1.5",
        "letter-spacing": "0.2px",
        color: colors.primaryDark
    }),
    shinyHeader1: Object.assign({}, KOROLEV_CONDENSED, {
        "font-size": "68px",
        "line-height": "0.94",
        "letter-spacing": "-0.72px"
    }),
    shinyHeader2: Object.assign({}, KOROLEV_CONDENSED, {
        "font-size": "50px",
        "line-height": "0.94",
        "letter-spacing": "-0.54px"
    }),
    shinyHeader3: Object.assign({}, KOROLEV_CONDENSED, {
        "font-size": "38px",
        "line-height": "0.95",
        "letter-spacing": "-0.36px"
    }),
    shinyHeader4: Object.assign({}, KOROLEV_CONDENSED, {
        "font-size": "29px",
        "line-height": "0.93",
        "letter-spacing": "-0.28px"
    }),
    shinyHeader5: Object.assign({}, KOROLEV_CONDENSED, {
        "font-size": "21px",
        "line-height": "0.9",
        "letter-spacing": "-0.2px"
    })
};
//since we are using only this
export const addMediaType = `screen and`;
// public/assets/sass/content/base/grid/_breakpoints.scss
export const mediaFeatures = {
    "for-smaller-mobile": `(max-width: 300px)`,
    "for-small-mobile": `(max-width: 375px)`,
    "for-mobile": `(max-width: ${extraSmallDevice - 0.1}em)`,
    "for-tablet": `(min-width: ${extraSmallDevice}em)`,
    "for-large-tablet": `(min-width: ${smallDevice}em)`,
    "for-mobile-and-tablet": `(max-width: ${mediumDevice - 0.1}em)`,
    "for-mobile-and-small-tablet": `(max-width: ${smallDevice - 0.1}em)`,
    "for-small-tablet-only": `(min-width: ${extraSmallDevice}em) and (max-width: ${smallDevice - 0.1}em)`,
    "for-large-tablet-only": `(min-width: ${smallDevice}em) and (max-width: ${mediumDevice - 0.1}em)`,
    "for-tablet-only": `(min-width: ${extraSmallDevice}em) and (max-width: ${mediumDevice - 0.1}em )`,
    "for-pc": `(min-width: ${mediumDevice}em)`,
    "for-large-pc": `(min-width: ${largeDevice}em)`,
    "for-slightly-large-pc": `(min-width: ${slightlyLargeDevice}em)`,
    "for-pc-only": `(min-width: ${mediumDevice}em) and (max-width: ${extraLargeDevice - 0.1}em )`,
    "for-hd": `(min-width: ${extraLargeDevice}em)`,
    "for-tablet-and-pc": `(min-width: ${extraSmallDevice}em) and (max-width: ${largeDevice - 0.1}em)`
};
let mediaQueries = {};
Object.keys(mediaFeatures).forEach(mq => (mediaQueries[mq] = `@media ${addMediaType} ${mediaFeatures[mq]}`));
const theme = {
    colors,
    xsPadding: "4px",
    smPadding: "8px",
    medPadding: "12px",
    lgPadding: "16px",
    medRadius: "12px",
    liveEventGamePillRadius: "27px",
    // public/assets/sass/content/base/grid/_breakpoints.scss
    mediaQueries: mediaQueries,
    // _misc_components.scss
    logoBackground: {
        backgroundPosition: "1800% 120%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "99%",
        backgroundColor: `${colors.white}`
    }
};
export const stdPadding = "16px";
export const medPadding = "12px";
export const stripHeightMed = "60px";
export const stripHeightMobile = "48px";
export const stripBorderThickness = "0px";
export const svgIconStyle = {
    height: "100%",
    width: "1em",
    display: "inline-block",
    verticalAlign: "middle"
};
export const fastTransition = "all 0.1s ease-in-out";
//Fonts
export const tinyFontSize = "12px";
export const smallFontSize = "14px";
const bigFontSize = "18px";
const stdFontSize = "16px";
const largeTabletBodySize = "1.38rem";
const pcBodySize = "1.07rem";
const largePcBodySize = "0.98rem";
export const HomePageBannerFontSize = {
    default: "25px",
    forTablet: "18px",
    forMobile: "14px",
    forCitySelectorMobile: "13px",
    forCitySelectorTablet: "20px"
};
export const bannerFontSize = {
    default: "27px",
    forLargeTablet: "2.435rem",
    forPc: "1.83rem",
    forLargePc: "1.825rem",
    forHd: "27.5px"
};
export const bodyFontSize = {
    default: tinyFontSize,
    forLargeTablet: largeTabletBodySize,
    forPc: pcBodySize,
    forLargePc: largePcBodySize,
    forHd: stdFontSize
};
export const titleFontSize = {
    default: smallFontSize,
    forLargeTablet: "1.55rem",
    forPc: "1.2rem",
    forLargePc: "1.11rem",
    forHd: bigFontSize
};
export const headerFontSize = {
    default: stdFontSize,
    forLargeTablet: "1.75rem",
    forPc: "1.35rem",
    forLargePc: "1.25rem",
    forHd: "22px"
};
export const gutterMarginTop = "36px";
export const gutterMarginBottom = "36px";
export const stdCardBorder = `1px solid ${colors["slightly-lighter-gray"]}`;
export const stdDashedBorder = `1px dashed ${colors["gray"]}`;
export default theme;
export const subtextFontSize = `
	font-size: 11px;
    @media screen and ${mediaFeatures["for-tablet"]} {
		font-size: 1.2rem;
	}
	@media screen and ${mediaFeatures["for-pc"]} {
		font-size: 0.95rem;
	}
	@media screen and ${mediaFeatures["for-large-pc"]} {
		font-size: 0.875rem;
	}
	@media screen and ${mediaFeatures["for-hd"]} {
		font-size: 14px;
	}
`;
export const bodyFontSizes = `
	font-size: ${bodyFontSize.default};
	@media screen and ${mediaFeatures["for-tablet"]} {
		font-size: ${bodyFontSize.forLargeTablet};
	}
	@media screen and ${mediaFeatures["for-pc"]} {
		font-size: ${bodyFontSize.forPc};
	}
	@media screen and ${mediaFeatures["for-large-pc"]} {
		font-size: ${bodyFontSize.forLargePc};
	}
	@media screen and ${mediaFeatures["for-hd"]} {
		font-size: ${bodyFontSize.forHd};
	}
`;
export const headerFontSizes = `
	font-size: ${headerFontSize.default};
	@media screen and ${mediaFeatures["for-tablet"]} {
		font-size: ${headerFontSize.forLargeTablet};
	}
	@media screen and ${mediaFeatures["for-pc"]} {
		font-size: ${headerFontSize.forPc};
	}
	@media screen and ${mediaFeatures["for-large-pc"]} {
		font-size: ${headerFontSize.forLargePc};
	}
	@media screen and ${mediaFeatures["for-hd"]} {
		font-size: ${headerFontSize.forHd};
	}
`;
export const titleFontSizes = `
	font-size: ${titleFontSize.default};
	@media screen and ${mediaFeatures["for-large-tablet"]} {
		font-size: ${titleFontSize.forLargeTablet};
	}
	@media screen and ${mediaFeatures["for-pc"]} {
		font-size: ${titleFontSize.forPc};
	}
	@media screen and ${mediaFeatures["for-large-pc"]} {
		font-size: ${titleFontSize.forLargePc};
	}
	@media screen and ${mediaFeatures["for-hd"]} {
		font-size: ${titleFontSize.forHd};
	}
`;
export const bannerFontSizes = `
	font-size: 25px;
	@media screen and ${mediaFeatures["for-large-tablet"]} {
		font-size: 2.435rem;
	}
	@media screen and ${mediaFeatures["for-pc"]} {
		font-size: 1.83rem;
	}
	@media screen and ${mediaFeatures["for-large-pc"]} {
		font-size: 1.825rem;
	}
	@media screen and ${mediaFeatures["for-hd"]} {
		font-size: 27.5px;
	}
`;
export const bodyRead = `
	${titleFontSizes}
	font-weight: normal;
  	line-height: 1.7;
`;
export const bigMobile = "8vw";
export const bigTablet = "5.5vw";
export const bigPc = "3.5vw";
export const bigHd = "48px";
export const subbannerFontSizes = `
	font-size: ${bigFontSize};
	@media screen and ${mediaFeatures["for-large-tablet"]} {
		font-size: 2.03rem;
	}
	@media screen and ${mediaFeatures["for-pc"]} {
		font-size: 1.67rem;
	}
	@media screen and ${mediaFeatures["for-large-pc"]} {
		font-size: 1.52rem;
	}
	@media screen and ${mediaFeatures["for-hd"]} {
		font-size: 24px;
	}
`;
export const superbannerFontSizes = `
	font-size: ${bannerFontSize.default};
	@media screen and ${mediaFeatures["for-large-tablet"]} {
		font-size: 3.85rem;
	}
	@media screen and ${mediaFeatures["for-pc"]} {
		font-size: 3rem;
	}
	@media screen and ${mediaFeatures["for-large-pc"]} {
		font-size: 2.85rem;
	}
	@media screen and ${mediaFeatures["for-hd"]} {
		font-size: 45px;
	}
`;
export const systemFonts = '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif';
export const InterFont = `Inter, ${systemFonts}`;
export const maxWidthContainer = `
    max-width: 1280px;
    margin: 0 auto;
`;
export const flexBox = (justifyContent, alignItems, flexWrap) => `
	display: flex;
	justify-content: ${justifyContent ? justifyContent : "center"};
	align-items: ${alignItems ? alignItems : "center"};
	flex-wrap: ${flexWrap ? flexWrap : "unset"};
`;
